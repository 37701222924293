const e = Symbol(),
  t = Symbol(),
  r = "a",
  n = "w";
let o = (e, t) => new Proxy(e, t);
const s = Object.getPrototypeOf,
  c = new WeakMap(),
  l = e => e && (c.has(e) ? c.get(e) : s(e) === Object.prototype || s(e) === Array.prototype),
  f = e => "object" == typeof e && null !== e,
  i = e => {
    if (Array.isArray(e)) return Array.from(e);
    const t = Object.getOwnPropertyDescriptors(e);
    return Object.values(t).forEach(e => {
      e.configurable = !0;
    }), Object.create(s(e), t);
  },
  u = e => e[t] || e,
  a = (s, c, f, p) => {
    if (!l(s)) return s;
    let g = p && p.get(s);
    if (!g) {
      const e = u(s);
      g = (e => Object.values(Object.getOwnPropertyDescriptors(e)).some(e => !e.configurable && !e.writable))(e) ? [e, i(e)] : [e], null == p || p.set(s, g);
    }
    const [y, h] = g;
    let w = f && f.get(y);
    return w && w[1].f === !!h || (w = ((o, s) => {
      const c = {
        f: s
      };
      let l = !1;
      const f = (e, t) => {
          if (!l) {
            let s = c[r].get(o);
            if (s || (s = {}, c[r].set(o, s)), e === n) s[n] = !0;else {
              let r = s[e];
              r || (r = new Set(), s[e] = r), r.add(t);
            }
          }
        },
        i = {
          get: (e, n) => n === t ? o : (f("k", n), a(Reflect.get(e, n), c[r], c.c, c.t)),
          has: (t, n) => n === e ? (l = !0, c[r].delete(o), !0) : (f("h", n), Reflect.has(t, n)),
          getOwnPropertyDescriptor: (e, t) => (f("o", t), Reflect.getOwnPropertyDescriptor(e, t)),
          ownKeys: e => (f(n), Reflect.ownKeys(e))
        };
      return s && (i.set = i.deleteProperty = () => !1), [i, c];
    })(y, !!h), w[1].p = o(h || y, w[0]), f && f.set(y, w)), w[1][r] = c, w[1].c = f, w[1].t = p, w[1].p;
  },
  p = (e, t, r, o) => {
    if (Object.is(e, t)) return !1;
    if (!f(e) || !f(t)) return !0;
    const s = r.get(u(e));
    if (!s) return !0;
    if (o) {
      const r = o.get(e);
      if (r && r.n === t) return r.g;
      o.set(e, {
        n: t,
        g: !1
      });
    }
    let c = null;
    try {
      for (const r of s.h || []) if (c = Reflect.has(e, r) !== Reflect.has(t, r), c) return c;
      if (!0 === s[n]) {
        if (c = ((e, t) => {
          const r = Reflect.ownKeys(e),
            n = Reflect.ownKeys(t);
          return r.length !== n.length || r.some((e, t) => e !== n[t]);
        })(e, t), c) return c;
      } else for (const r of s.o || []) if (c = !!Reflect.getOwnPropertyDescriptor(e, r) != !!Reflect.getOwnPropertyDescriptor(t, r), c) return c;
      for (const n of s.k || []) if (c = p(e[n], t[n], r, o), c) return c;
      return null === c && (c = !0), c;
    } finally {
      o && o.set(e, {
        n: t,
        g: c
      });
    }
  },
  g = t => !!l(t) && e in t,
  y = e => l(e) && e[t] || null,
  h = (e, t = !0) => {
    c.set(e, t);
  },
  w = (e, t, r) => {
    const o = [],
      s = new WeakSet(),
      c = (e, l) => {
        if (s.has(e)) return;
        f(e) && s.add(e);
        const i = f(e) && t.get(u(e));
        if (i) {
          var a, p;
          if (null == (a = i.h) || a.forEach(e => {
            const t = `:has(${String(e)})`;
            o.push(l ? [...l, t] : [t]);
          }), !0 === i[n]) {
            const e = ":ownKeys";
            o.push(l ? [...l, e] : [e]);
          } else {
            var g;
            null == (g = i.o) || g.forEach(e => {
              const t = `:hasOwn(${String(e)})`;
              o.push(l ? [...l, t] : [t]);
            });
          }
          null == (p = i.k) || p.forEach(t => {
            r && !("value" in (Object.getOwnPropertyDescriptor(e, t) || {})) || c(e[t], l ? [...l, t] : [t]);
          });
        } else l && o.push(l);
      };
    return c(e), o;
  },
  O = e => {
    o = e;
  };
export { w as affectedToPathList, a as createProxy, y as getUntracked, p as isChanged, h as markToTrack, O as replaceNewProxy, g as trackMemo };
